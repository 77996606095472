import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './i18n';
import './index.css';

// @material-tailwind/react
import { ThemeProvider } from '@material-tailwind/react';

declare global {
  interface Window {
    analytics: {
      identify: (param1: string, param2: any) => void;
      track: (param1: string, param2?: any) => void;
      alias: (param: string) => void;
      load: (param: string) => void;
      page: () => void;
      _writeKey: string;
    };
  }
}



const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter>
        <ThemeProvider>
          <App />
        </ThemeProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
