import React, { useRef, useState, useEffect } from 'react';

const ComingSoon: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null); // Reference to the video element
  const [isMuted, setIsMuted] = useState<boolean>(true); // State to handle mute/unmute
  const [videoEnded, setVideoEnded] = useState<boolean>(false); // State to check if the video has ended

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const playVideo = async () => {
        try {
          await video.play(); // Try to play the video
        } catch (err) {
          console.log('Autoplay prevented, waiting for user interaction');
        }
      };

      playVideo(); // Call the function to try playing the video

      const handleVideoEnd = () => {
        setVideoEnded(true); // Set videoEnded to true when video ends
      };

      video.addEventListener('ended', handleVideoEnd);

      return () => {
        video.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, []);

  const toggleSound = () => {
    const video = videoRef.current;
    if (video) {
      setIsMuted(!isMuted);
      video.muted = isMuted; // Toggle mute/unmute
      if (video.paused) {
        video.play(); // Ensure the video plays if it was paused
      }
    }
  };

  return (
    <div className="relative w-full h-screen overflow-hidden bg-black">
      {!videoEnded ? (
        <>
          <video
            ref={videoRef}
            muted={isMuted}
            autoPlay
            playsInline
            className="absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2"
          >
            <source src="/assets/video/coming-soon.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button
            onClick={toggleSound}
            className="absolute bottom-5 right-5"
          >
            {isMuted ? 
              <img src="/assets/icons/volume-off.svg" alt="Mute" className="w-10" />
              : 
              <img src="/assets/icons/volume-on.svg" alt="Mute" className="w-10" />
            }
          </button>
        </>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <img src="/assets/icons/logo.png" alt="" className="w-16" />

        </div>
      )}
    </div>
  );
};

export default ComingSoon;
