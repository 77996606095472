import { Route, Routes } from 'react-router-dom';
import AppLayout from 'src/layout/AppLayout';
import ComingSoon from 'src/pages/ComingSoon';

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<ComingSoon />} />
      {/* <Route path="/" element={<AppLayout />}></Route> */}
    </Routes>
  );
}
